const Configurl = {
    Url : "https://apiadmin.bxoks.online/api/v1/",
    TokenBotDiscord : "OTY5MDkyMjI1NjE3MjQ4MzM2.GXB-rx.doEECjrps-bWSf5Er11y37YNAO_y9NjX9Q1XyM",
    headers: () => {
        return{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('BXADMIN_Token'),
            },
        };
    },
}

export default Configurl