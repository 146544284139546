import React, { useEffect, useState } from "react";
import NavBarUser from "../Navbar/Navbar";
import Swal from "sweetalert2";
import Configurl from "../../Config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../Component/Loading";
export default function Itemowner() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id"); // ดึง ID จาก query string
  const Navigate = useNavigate();
  // const [config, setConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [player, setPlayer] = useState(null); // ใช้ตัวแปร player แทน players เพื่อเก็บผู้เล่นที่ตรงกับ id นั้น
  const [avatarUrl, setavatarUrl] = useState(null); // ใช้ตัวแปร player แทน players เพื่อเก็บผู้เล่นที่ตรงกับ id นั้น
  const [coords, setcoords] = useState(null);
  const [discord, setdiscord] = useState(null);
  const [inventory, setinventory] = useState(null);
  useEffect(() => {
    fetchbanner()
    fetchverify();
    fetchPlayerOnline();
    Getinventory();
  }, []);
  const [banner, setbanner] = useState();
  const fetchbanner = async () => {
    try {
      const response = await axios.get(
        Configurl.Url + "banner",
      );
      if (response.data.success) {
        setbanner(response.data.text)
      } 
    } catch (error) {}
  };
  const fetchverify = async () => {
    try {
      const response = await axios.get(
        Configurl.Url + "verify",
        Configurl.headers()
      );
      if (response.data.status == 200) {
      } else {
        localStorage.removeItem("BX_Token");
        Navigate("/login");
      }
    } catch (error) {}
  };

  const fetchPlayerOnline = async () => {
    try {
      const response = await axios.post(
        `${Configurl.Url}useronline`,
        {},
        Configurl.headers()
      );
      const coords = await axios.post(
        `${Configurl.Url}getcoords`,
        {},
        Configurl.headers()
      );
      setcoords(coords.data.data);
      // เรียงลำดับ players ตาม id จากน้อยไปมาก
      const sortedPlayers = response.data.sort((a, b) => a.id - b.id);

      // กรองหาผู้เล่นที่มี id ตรงกับที่ส่งมา
      const foundPlayer = sortedPlayers.find((player) => player.id == id);

      if (foundPlayer) {
        setPlayer(foundPlayer);
        fetchDiscord(foundPlayer);
        setIsLoading(false); // Stop loading when config is loaded
      } else {
        fetchPlayerOnline();
        setIsLoading(false); // Stop loading when config is loaded
      }
    } catch (error) {}
  };

  const fetchDiscord = async (foundPlayer) => {
    try {
      // ค้นหาตัวระบุ discord ใน identifiers
      let discordId = "";
      if (foundPlayer) {
        foundPlayer.identifiers.forEach((identifier) => {
          // ตรวจสอบ identifier ที่เป็นของ Discord
          if (identifier.startsWith("discord:")) {
            discordId = identifier.split(":")[1]; // ดึง discord ID
          }
        });
      }

      // ตรวจสอบว่าพบ discordId หรือไม่
      if (discordId) {
        setdiscord(discordId);
        const data = {
          iddiscord: discordId,
          token: Configurl.TokenBotDiscord,
        };
        // เรียก Discord API โดยใช้ Discord ID
        const response = await axios.post(
          `${Configurl.Url}discordprofile`,
          data
        );

        const avatarUrl = `https://cdn.discordapp.com/avatars/${
          response.data.id
        }/${response.data.avatar}.${
          response.data.avatar.startsWith("a_") ? "gif" : "png"
        }`;
        setIsLoading(false); // Stop loading when config is loaded
        setavatarUrl(avatarUrl);
      } else {
        fetchPlayerOnline();
        setIsLoading(false); // Stop loading when config is loaded
      }
    } catch (error) {
      setIsLoading(false); // Stop loading when config is loaded
    }
  };

  const Getinventory = async () => {
    try {
      const data = {
        playerId: parseInt(id, 10),
      };

      const res = await axios.post(
        `${Configurl.Url}getitem`,
        data,
        Configurl.headers()
      );
      if (res.data.success) {
        setinventory(res.data.data);
        setIsLoading(false); // Stop loading when config is loaded
      }
    } catch (error) {}
  };

  const GiveItem = async(item) => {
    try {
        const { value: count } = await Swal.fire({
            title: `คุณจะให้ ${item.label} กับ \n ID : ${player.id}\n จำนวนเท่าไหร่`,
            input: "text",
            imageUrl: "/images/items/" +item.name+ ".png",
            inputLabel: "จำนวน",
            showCancelButton: true,
            inputValidator: (value) => {
              // Convert value to an integer
              const intValue = parseInt(value, 10);
          
              // Check if the value is a valid number
              if (isNaN(intValue)) {
                return "ใส่จำนวนที่เป็นตัวเลข";
              }
              // Optional: you can add additional checks if needed, e.g., positive number
              if (intValue <= 0) {
                return "จำนวนต้องมากกว่า 0";
              }
            },
          });
          const data = {
            playerId: parseInt(id, 10),
            item : {
                itemname : item.name,
                count : parseInt(count, 10)
            }
          };
    
          const res = await axios.post(`${Configurl.Url}giveitemplayer`, data, Configurl.headers());
          if (res.data.success) {
            Swal.fire({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              });
          }

    } catch (error) {
        
    }
  }

  const GiveWeapon = async(item) => {
    try {
        const { value: count } = await Swal.fire({
            title: `คุณจะให้ ${item.name} กับ\n ID : ${player.id}\n  จำนวนเท่าไหร่`,
            input: "text",
            imageUrl: "/images/items/" +item.name+ ".png",
            inputLabel: "จำนวน",
            showCancelButton: true,
            inputValidator: (value) => {
              // Convert value to an integer
              const intValue = parseInt(value, 10);
          
              // Check if the value is a valid number
              if (isNaN(intValue)) {
                return "ใส่จำนวนที่เป็นตัวเลข";
              }
              // Optional: you can add additional checks if needed, e.g., positive number
              if (intValue <= 0) {
                return "จำนวนต้องมากกว่า 0";
              }
            },
          });
          const data = {
            playerId: parseInt(id, 10),
            weapon : {
                weaponname : item.name,
                count : parseInt(count, 10)
            }
          };
    
          const res = await axios.post(`${Configurl.Url}giveweaponplayer`, data,Configurl.headers());
          if (res.data.success) {
            Swal.fire({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              });
          }

    } catch (error) {
        
    }
  }

  const GiveMoney = async() => {
    try {
        const { value: count } = await Swal.fire({
            title: `คุณจะให้ เงิน กับ\n ID : ${player.id}\n  จำนวนเท่าไหร่`,
            input: "text",
            imageUrl: "/images/items/money.png",
            inputLabel: "จำนวน",
            showCancelButton: true,
            inputValidator: (value) => {
              // Convert value to an integer
              const intValue = parseInt(value, 10);
          
              // Check if the value is a valid number
              if (isNaN(intValue)) {
                return "ใส่จำนวนที่เป็นตัวเลข";
              }
              // Optional: you can add additional checks if needed, e.g., positive number
              if (intValue <= 0) {
                return "จำนวนต้องมากกว่า 0";
              }
            },
          });
          const data = {
            playerId: parseInt(id, 10),
            money : {
                moneytype : 'money',
                count : parseInt(count, 10)
            }
          };
    
          const res = await axios.post(`${Configurl.Url}givemoneyplayer`, data, Configurl.headers());
          if (res.data.success) {
            Swal.fire({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              });
          }

    } catch (error) {
        
    }
  }

  const GiveBank = async() => {
    try {
        const { value: count } = await Swal.fire({
            title: `คุณจะให้ เงินในธนาคาร กับ\n ID : ${player.id}\n  จำนวนเท่าไหร่`,
            input: "text",
            imageUrl: "/images/items/bank.png",
            inputLabel: "จำนวน",
            showCancelButton: true,
            inputValidator: (value) => {
              // Convert value to an integer
              const intValue = parseInt(value, 10);
          
              // Check if the value is a valid number
              if (isNaN(intValue)) {
                return "ใส่จำนวนที่เป็นตัวเลข";
              }
              // Optional: you can add additional checks if needed, e.g., positive number
              if (intValue <= 0) {
                return "จำนวนต้องมากกว่า 0";
              }
            },
          });
          const data = {
            playerId: parseInt(id, 10),
            money : {
                moneytype : 'bank',
                count : parseInt(count, 10)
            }
          };
    
          const res = await axios.post(`${Configurl.Url}givemoneyplayer`, data, Configurl.headers());
          if (res.data.success) {
            Swal.fire({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              });
          }

    } catch (error) {
        
    }
  }

  const GiveMoney_black = async() => {
    try {
        const { value: count } = await Swal.fire({
            title: `คุณจะให้ เงินผิดกฎหมาย กับ\n ID : ${player.id}\n  จำนวนเท่าไหร่`,
            input: "text",
            imageUrl: "/images/items/black_money.png",
            inputLabel: "จำนวน",
            showCancelButton: true,
            inputValidator: (value) => {
              // Convert value to an integer
              const intValue = parseInt(value, 10);
          
              // Check if the value is a valid number
              if (isNaN(intValue)) {
                return "ใส่จำนวนที่เป็นตัวเลข";
              }
              // Optional: you can add additional checks if needed, e.g., positive number
              if (intValue <= 0) {
                return "จำนวนต้องมากกว่า 0";
              }
            },
          });
          const data = {
            playerId: parseInt(id, 10),
            money : {
                moneytype : 'black_money',
                count : parseInt(count, 10)
            }
          };
    
          const res = await axios.post(`${Configurl.Url}givemoneyplayer`, data, Configurl.headers());
          if (res.data.success) {
            Swal.fire({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              });
          }

    } catch (error) {
        
    }
  }
  return (
    <>
      <NavBarUser />
      <div class="flex h-screen flex-1 flex-col py-10 px-2 sm:px-28 md:px-5 lg:px-24 xl:px-48 2xl:px-64 kanit-regular ">
        <div class="h-10 kanit-regular drop-shadow-lg">
          <div class="w-full h-full bg-base-2 rounded-3xl flex justify-between items-center">
            <div class="w-2/12 h-full bg-base-1 rounded-l-3xl flex justify-center items-center">
              <p class="text-center text-base-2 text-xl">ประกาศ</p>
            </div>
            <div class="w-10/12 h-full overflow-hidden flex justify-center items-center">
              <p class="animate-scrolling-left1 text-center text-base-1 p-2 text-sm md:text-xl">
                { banner ? <>{banner}</> : <>BX DEV | ประกาศ อัพเดตสคริปต์ WEBADMIN สามารถอัพได้แล้ววันนี้ !!!</>}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-10 w-full bg-base-2  border-8 border-base-3 rounded-xl overflow-x-auto p-10">
          <div className="w-full flex">
            <div className="hidden lg:block w-52 md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 h-64 rounded-xl bg-base-2">
              {/* This image will be hidden on small screens */}
              <img
                className="hidden md:block w-full h-full rounded-xl"
                src={avatarUrl}
                alt=""
              />
              <p className="text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg w-full bg-base-2 m-2 text-center rounded-xl">
                {/* {config.ServerName} */}
              </p>
            </div>
            <div className="flex flex-col">
              <div className="w-full rounded-xl flex md:justify-center">
                {/* ตรวจสอบว่ามี player ก่อนเข้าถึง properties */}
                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">ID</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    {player ? <>{player.id}</> : <></>}
                  </p>
                </div>

                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Name</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    {player ? <>{player.name}</> : <></>}
                  </p>
                </div>
              </div>
              <div className="w-full rounded-xl my-2 flex md:justify-center">
                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Job</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    Jobname
                  </p>
                </div>

                <div className="mx-2">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Group</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    GroupName
                  </p>
                </div>

                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Ping</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    {player ? <>{player.ping}</> : <></>}
                  </p>
                </div>
              </div>

              <div className="w-full rounded-xl my-2 flex md:justify-center">
                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Steam</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    {player ? <>{player.identifiers[0]}</> : <></>}
                  </p>
                </div>

                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Discord ID</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    {discord ? <>discord:{discord}</> : <></>}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  rounded-xl my-10 border-4 border-base-3/50 p-5">
            {isLoading && <LoadingPage />}
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5 gap-5">
              {inventory ? (
                <>
                  <div onClick={GiveMoney} className="m-5bg-gray-900 bg-base-3/50 text-white rounded-lg w-36 h-36 flex flex-col items-center justify-between md:ml-5">
                    <div className="text-sm p-1 flex">
                      <img
                        src={Configurl.Url+"image/img/grid-svgrepo-com.svg"}
                        className="w-3 mx-1"
                      />{" "}
                      {/* {inventory.money} */}
                    </div>
                    <img
                      src={Configurl.Url+"image/item/money.png"}
                      alt=""
                      className="w-12 h-12"
                    />
                    <div className="bg-base-3 text-center text-sm text-neutral-50 rounded-md w-full py-1">
                      Money
                    </div>
                  </div>
                  <div onClick={GiveBank} className="bg-gray-900 bg-base-3/50 text-white rounded-lg w-36 h-36 flex flex-col items-center justify-between md:ml-5">
                    <div className="text-sm p-1 flex">
                      <img
                        src={Configurl.Url+"image/img/grid-svgrepo-com.svg"}
                        className="w-3 mx-1"
                      />{" "}
                      {/* {inventory.bank} */}
                    </div>
                    <img
                      src={Configurl.Url+"image/item/bank.png"}
                      alt=""
                      className="w-12 h-12"
                    />
                    <div className="bg-base-3 text-center text-sm text-neutral-50 rounded-md w-full py-1">
                      Bank
                    </div>
                  </div>

                  <div onClick={GiveMoney_black} className="bg-gray-900 bg-base-3/50 text-white rounded-lg w-36 h-36 flex flex-col items-center justify-between md:ml-5">
                    <div className="text-sm p-1 flex">
                      <img
                        src= {Configurl.Url+"image/img/grid-svgrepo-com.svg"}
                        className="w-3 mx-1"
                      />{" "}
                      {inventory.black_money}
                    </div>
                    <img
                      src= {Configurl.Url+"image/item/black_money.png"}
                      alt=""
                      className="w-12 h-12"
                    />
                    <div className="bg-base-3 text-center text-sm text-neutral-50 rounded-md w-full py-1">
                      black_money
                    </div>
                  </div>

                  {inventory.inventory.map((item, index) => (
                    <div
                      key={index}
                      onClick={e => GiveItem(item)}
                      className="bg-gray-900 bg-base-3/50 text-white rounded-lg w-36 h-36 flex flex-col items-center justify-between md:ml-5"
                    >
                      <div className="text-sm p-1 flex">
                        <img
                          src={Configurl.Url+"image/img/grid-svgrepo-com.svg"}
                          className="w-3 mx-1"
                        />{" "}
                        {item.name}
                      </div>
                      <img
                        src={`${Configurl.Url}image/item/${item.name}.png`}
                        alt={item.name}
                        className="w-12 h-12"
                      />
                      <div className="bg-base-3 text-center text-sm text-neutral-50 rounded-md w-full py-1">
                        {item.label}
                      </div>
                    </div>
                  ))}

                  {inventory.weapons.map((item, index) => (
                    <div
                      key={index}
                      onClick={e => GiveWeapon(item)}
                      className="bg-gray-900 bg-base-3/50 text-white rounded-lg w-36 h-36 flex flex-col items-center justify-between md:ml-5"
                    >
                      <div className="text-sm p-1 flex">
                        <img
                          src={Configurl.Url+"image/img/grid-svgrepo-com.svg"}
                          className="w-3 mx-1"
                        />{" "}
                        {item.name}
                      </div>
                      <img
                        src={`${Configurl.Url}image/item/${item.name}.png`}
                        alt={item.name}
                        className="w-12 h-12"
                      />
                      <div className="bg-base-3 text-center text-sm text-neutral-50 rounded-md w-full py-1">
                        {item.name}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div>No inventory available.</div> // เพิ่มข้อความให้แสดงเมื่อไม่มี inventory
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
