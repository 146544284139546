import React, { useEffect, useState } from "react";
import NavBarUser from "../Navbar/Navbar";
import Swal from "sweetalert2";
import Configurl from "../../Config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../Component/Loading";
export default function User() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id"); // ดึง ID จาก query string
  const Navigate = useNavigate();
  // const [config, setConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [player, setPlayer] = useState(null); // ใช้ตัวแปร player แทน players เพื่อเก็บผู้เล่นที่ตรงกับ id นั้น
  const [avatarUrl, setavatarUrl] = useState(null); // ใช้ตัวแปร player แทน players เพื่อเก็บผู้เล่นที่ตรงกับ id นั้น
  const [coords, setcoords] = useState(null);
  const [discord, setdiscord] = useState(null);
  useEffect(() => {
    fetchbanner();
    fetchverify();
    fetchPlayerOnline();
  }, []);

  const [banner, setbanner] = useState();
  const fetchbanner = async () => {
    try {
      const response = await axios.get(
        Configurl.Url + "banner",
      );
      if (response.data.success) {
        setbanner(response.data.text)
      } 
    } catch (error) {}
  };
  const fetchverify = async () => {
    try {
      const response = await axios.get(
        Configurl.Url + "verify",
        Configurl.headers()
      );
      if (response.data.status == 200) {
      } else {
        localStorage.removeItem("BX_Token");
        Navigate("/login");
      }
    } catch (error) {}
  };

  const fetchPlayerOnline = async () => {
    try {
      const response = await axios.post(
        `${Configurl.Url}useronline`,
        {},
        Configurl.headers()
      );
      const coords = await axios.post(
        `${Configurl.Url}getcoords`,
        {},
        Configurl.headers()
      );
      setcoords(coords.data.data);
      // เรียงลำดับ players ตาม id จากน้อยไปมาก
      const sortedPlayers = response.data.sort((a, b) => a.id - b.id);

      // กรองหาผู้เล่นที่มี id ตรงกับที่ส่งมา
      const foundPlayer = sortedPlayers.find((player) => player.id == id);

      if (foundPlayer) {
        setPlayer(foundPlayer);
        fetchDiscord(foundPlayer);
        setIsLoading(false); // Stop loading when config is loaded
      } else {
        fetchPlayerOnline();
        // setIsLoading(false); // Stop loading when config is loaded
      }
    } catch (error) {}
  };

  const fetchDiscord = async (foundPlayer) => {
    try {
      // ค้นหาตัวระบุ discord ใน identifiers
      let discordId = "";
      if (foundPlayer) {
        foundPlayer.identifiers.forEach((identifier) => {
          // ตรวจสอบ identifier ที่เป็นของ Discord
          if (identifier.startsWith("discord:")) {
            discordId = identifier.split(":")[1]; // ดึง discord ID
          }
        });
      }

      // ตรวจสอบว่าพบ discordId หรือไม่
      if (discordId) {
        setdiscord(discordId)
        const data = {
          iddiscord: discordId,
          token: Configurl.TokenBotDiscord,
        };
        // เรียก Discord API โดยใช้ Discord ID
        const response = await axios.post(
          `${Configurl.Url}discordprofile`,
          data
        );

        const avatarUrl = `https://cdn.discordapp.com/avatars/${
          response.data.id
        }/${response.data.avatar}.${
          response.data.avatar.startsWith("a_") ? "gif" : "png"
        }`;
        setIsLoading(false); // Stop loading when config is loaded
        setavatarUrl(avatarUrl);
      } else {
        fetchPlayerOnline();
        setIsLoading(false); // Stop loading when config is loaded
      }
    } catch (error) {
      setIsLoading(false); // Stop loading when config is loaded
    }
  };

  //   if (isLoading || !config) {
  //     return <Loading />; // Show the loading component
  //   }

  //   if (isLoading || !player) {
  //     return <Loading />; // ถ้าไม่พบผู้เล่นจะแสดงข้อความนี้
  //   }

  const Kill = async () => {
    try {
      Swal.fire({
        title: `คุณต้องการ ฆ่า ${player.name} ใช่หรือไม่ ??`,
        showCancelButton: true,
        confirmButtonText: "ใช่",
        denyButtonText: `ไม่ใช่`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            playerId: parseInt(id, 10),
          };

          const res = await axios.post(
            `${Configurl.Url}killplayer`,
            data,
            Configurl.headers()
          );
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    } catch (error) {}
  };

  const Heal = async () => {
    try {
      Swal.fire({
        title: `คุณต้องการ ฮีล ${player.name} ใช่หรือไม่ ??`,
        showCancelButton: true,
        confirmButtonText: "ใช่",
        denyButtonText: `ไม่ใช่`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            playerId: parseInt(id, 10),
          };

          const res = await axios.post(
            `${Configurl.Url}healplayer`,
            data,
            Configurl.headers()
          );
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    } catch (error) {}
  };

  const Revive = async () => {
    try {
      Swal.fire({
        title: `คุณต้องการ ชุบ ${player.name} ใช่หรือไม่ ??`,
        showCancelButton: true,
        confirmButtonText: "ใช่",
        denyButtonText: `ไม่ใช่`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            playerId: parseInt(id, 10),
          };

          const res = await axios.post(
            `${Configurl.Url}reviveplayer`,
            data,
            Configurl.headers()
          );
          if (res.data.success) {
            if (res.data.success) {
              Swal.fire({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  const Reskin = async () => {
    try {
      Swal.fire({
        title: `คุณต้องการ รีสกิน ${player.name} ใช่หรือไม่ ??`,
        showCancelButton: true,
        confirmButtonText: "ใช่",
        denyButtonText: `ไม่ใช่`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            playerId: parseInt(id, 10),
          };

          const res = await axios.post(
            `${Configurl.Url}reskinplayer`,
            data,
            Configurl.headers()
          );
          if (res.data.success) {
            if (res.data.success) {
              Swal.fire({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  const Basicstatus = async () => {
    try {
      Swal.fire({
        title: `คุณต้องการ ให้อาหารเต็ม ${player.name} ใช่หรือไม่ ??`,
        showCancelButton: true,
        confirmButtonText: "ใช่",
        denyButtonText: `ไม่ใช่`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            playerId: parseInt(id, 10),
          };

          const res = await axios.post(
            `${Configurl.Url}basicstatus`,
            data,
            Configurl.headers()
          );
          if (res.data.success) {
            if (res.data.success) {
              Swal.fire({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  const Openinv = () => {
    try {
      Navigate(`/openinventory?id=${id}`);
    } catch (error) {}
  };

  const Item = () => {
    try {
      Navigate(`/item?id=${id}`);
    } catch (error) {}
  };

  const Bring = async (coords) => {
    try {
      Swal.fire({
        title: `คุณต้องการ ดึง ${player.name} ไปที่ ${coords.name} ใช่หรือไม่ ??`,
        showCancelButton: true,
        confirmButtonText: "ใช่",
        denyButtonText: `ไม่ใช่`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            playerId: parseInt(id, 10),
            bring: {
              namebring: coords.name,
              coordsbring: coords.coords,
            },
          };
          console.log(data);
          const res = await axios.post(
            `${Configurl.Url}bring`,
            data,
            Configurl.headers()
          );
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    } catch (error) {}
  };
  return (
    <>
      <NavBarUser />
      <div class="flex h-screen flex-1 flex-col py-10 px-2 sm:px-28 md:px-5 lg:px-24 xl:px-48 2xl:px-64 kanit-regular">
        <div class="h-10 kanit-regular drop-shadow-lg">
          <div class="w-full h-full bg-base-2 rounded-3xl flex justify-between items-center">
            <div class="w-2/12 h-full bg-base-1 rounded-l-3xl flex justify-center items-center">
              <p class="text-center text-base-2 text-xl">ประกาศ</p>
            </div>
            <div class="w-10/12 h-full overflow-hidden flex justify-center items-center">
              <p class="animate-scrolling-left1 text-center text-base-1 p-2 text-sm md:text-xl">
                { banner ? <>{banner}</> : <>BX DEV | ประกาศ อัพเดตสคริปต์ WEBADMIN สามารถอัพได้แล้ววันนี้ !!!</>}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-10 w-full bg-base-2  border-8 border-base-3 rounded-xl overflow-x-auto p-10">
          <div className="w-full flex">
            <div className="hidden lg:block w-52 md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 h-64 rounded-xl bg-base-2">
              {/* This image will be hidden on small screens */}
              <img
                className="hidden md:block w-full h-full rounded-xl"
                src={avatarUrl}
                alt=""
              />
              <p className="text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg w-full bg-base-2 m-2 text-center rounded-xl">
                {/* {config.ServerName} */}
              </p>
            </div>
            <div className="flex flex-col">
              <div className="w-full rounded-xl flex md:justify-center">
                {/* ตรวจสอบว่ามี player ก่อนเข้าถึง properties */}
                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">ID</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    {player ? <>{player.id}</>:<></>}
                  </p>
                </div>

                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Name</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                  {player ? <>{player.name}</>:<></>}
                  </p>
                </div>
              </div>
              <div className="w-full rounded-xl my-2 flex md:justify-center">
                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Job</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    Jobname
                  </p>
                </div>

                <div className="mx-2">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Group</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                    GroupName
                  </p>
                </div>

                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Ping</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                  {player ? <>{player.ping}</>:<></>}
                  </p>
                </div>
              </div>

              <div className="w-full rounded-xl my-2 flex md:justify-center">
                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Steam</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                  {player ? <>{player.identifiers[0]}</>:<></>}
                  </p>
                </div>

                <div className="mx-5">
                  <span className="md:text-lg text-xs">
                    <p className="m-1">Discord ID</p>
                  </span>
                  <p className="md:px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg  border-2 border-base-3/50 p-2 md:text-center lg:text-center xl:text-center 2xl:text-center rounded-xl">
                  {discord ? <>discord:{discord}</>:<></>}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  rounded-xl mt-10 border-4 border-base-3/50 p-5">
            {isLoading && <LoadingPage />}

            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 w-full bg-base-2">
              <div className="">
                <p
                  onClick={Kill}
                  className="px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg border-2 border-base-3/50 hover:bg-base-3 hover:text-base-2 p-2 text-center rounded-xl"
                >
                  ฆ่า
                </p>
              </div>

              <div className="">
                <p
                  onClick={Revive}
                  className="px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg border-2 border-base-3/50 hover:bg-base-3 hover:text-base-2 p-2 text-center rounded-xl"
                >
                  ชุบ
                </p>
              </div>

              <div className="">
                <p
                  onClick={Heal}
                  className="px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg border-2 border-base-3/50 hover:bg-base-3 hover:text-base-2 p-2 text-center rounded-xl"
                >
                  ฮีล
                </p>
              </div>

              <div className="">
                <p
                  onClick={Item}
                  className="px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg border-2 border-base-3/50 hover:bg-base-3 hover:text-base-2 p-2 text-center rounded-xl"
                >
                  ให้สิ่งของ
                </p>
              </div>

              <div className="">
                <p
                  onClick={(e) => Openinv(player.id)}
                  className="px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg border-2 border-base-3/50 hover:bg-base-3 hover:text-base-2 p-2 text-center rounded-xl"
                >
                  เปิดกระเป๋า
                </p>
              </div>

              <div className="">
                <p
                  onClick={Basicstatus}
                  className="px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg border-2 border-base-3/50 hover:bg-base-3 hover:text-base-2 p-2 text-center rounded-xl"
                >
                  อาหารเต็ม
                </p>
              </div>

              <div className="">
                <p
                  onClick={Reskin}
                  className="px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg border-2 border-base-3/50 hover:bg-base-3 hover:text-base-2 p-2 text-center rounded-xl"
                >
                  Reskin
                </p>
              </div>

              {coords ? (
                coords.map((coords, index) => (
                  <>
                    <div key={index} className="">
                      <p
                        onClick={(e) => Bring(coords)}
                        className="px-10 text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-lg border-2 border-base-3/50 hover:bg-base-3 hover:text-base-2 p-2 text-center rounded-xl"
                      >
                        {coords.name}
                      </p>
                    </div>
                  </>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
