import React from "react";

import BXOK from "../../img/User/BXOK.png";
import HomePage from "../../img/User/icon/HomePage.png";
import Callfemale from "../../img/User/icon/Callfemale.png";
import Login from "../../img/User/icon/Login.png";
import Refresh from "../../img/User/icon/Refresh.png";
import Name from "../../img/User/icon/Name.png";
import index from "../../img/User/icon/Index.png";
import MaleUser from "../../img/User/icon/MaleUser.png";
import { useNavigate } from "react-router-dom";

export default function NavBarUser() {
  const Navigate = useNavigate();
  const redirectToFacebook = () => {
    // ใช้ window.location.href สำหรับ URL ภายนอก
    window.location.href = "https://discord.gg/b9ZPFmQFtC";
  };
  return (
    <>
      <div className="flex h-24 flex-1 flex-col  sm:px-28 md:px-5 lg:px-24 xl:px-48 2xl:px-64 kanit-regular">
        <div className="w-full h-full bg-base-2 rounded-3xl mt-10 flex justify-between items-center">
          <div className="hidden md:flex items-center">
            <img src={BXOK} className="m-2 w-14 rounded-full" />
            <span className="text-2xl ml-4 kanit-black-italic tracking-[.5em] opacity-20 text-base-1">
              A D M I N
            </span>
          </div>

          <div className="flex space-x-6 mx-auto md:mx-5 md:text-lg text-xs ">
            {/* <div onClick={e => Navigate('/')} className="flex items-center">
              <img src={HomePage} className="md:w-6 md:h-6 w-4 h-4 mr-2" />
              <p className="kanit-regular text-base-1">หน้าหลัก</p>
            </div> */}
            <div
              onClick={(e) => Navigate("/owner")}
              className="flex items-center"
            >
              <img src={MaleUser} className="md:w-6 md:h-6 w-4 h-4 mr-2" />
              <p className="kanit-regular text-base-1">ผู้เล่น</p>
            </div>
            <div
              onClick={(e) => Navigate("/owner/admin")}
              className="flex items-center"
            >
              <img src={MaleUser} className="md:w-6 md:h-6 w-4 h-4 mr-2" />
              <p className="kanit-regular text-base-1">แอดมิน</p>
            </div>
            <div
              onClick={(e) => Navigate("/owner/log")}
              className="flex items-center"
            >
              <img src={index} className="md:w-6 md:h-6 w-4 h-4 mr-2" />
              <p className="kanit-regular text-base-1">LogAll</p>
            </div>
            <div onClick={redirectToFacebook} className="flex items-center">
              <img src={Callfemale} className="md:w-6 md:h-6 w-4 h-4 mr-2" />
              <p className="kanit-regular text-base-1">ติดต่อเรา</p>
            </div>
            <div className="flex items-center bg-base-1 rounded-lg p-2">
              {/* <img src={Name} className="md:w-6 md:h-6 w-4 h-4 mr-2" /> */}
              <p className="kanit-regular text-base-2">BX | Dev</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
