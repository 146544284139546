import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Configurl from "../Config";
import { useState } from "react";

export default function Login() {
  const Navigate = useNavigate();
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");

  const register = async () => {
    Navigate("/register");
  };

  const login = async () => {
    try {
      const data = {
        username: Username,
        password: Password,
      };
      const res = await axios.post(Configurl.Url + "login", data);
      console.log(res)
      if (res.data.status == 200) {
        const token = res.data.token;
        localStorage.setItem("BXADMIN_Token", token);
        // payload()
        Swal.fire({
          title: res.data.message,
          text: res.data.message,
          icon: "success",
          timer: 1500,
        });
        if(res.data.server){
        Navigate('/owner')

        } else {
          Navigate('/')

        }
      }
      if (res.data.status == 400) {
        Swal.fire({
          title: res.data.error,
          text: res.data.error,
          icon: "error",
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: error.message,
        icon: "error",
        timer: 1500,
      });
    }
  };

  return (
    <>
      <div className="flex h-screen flex-1 flex-col px-20 py-20 sm:px-32 md:px-44 lg:px-64 xl:px-96 2xl:px-96 sm:py-32 md:py-36 lg:py-36 xl:py-44 2xl:py-44 kanit-regular">
        <div className=" w-full bg-base-2 xl:w-10/12 2xl:w-6/12 xl:mx-44 2xl:mx-52 border-8 border-base-3 rounded-xl ">
          <span className="text-center text-2xl">
            <p className="m-5">ลงขื่อเข้าใช้งาน</p>
          </span>
          <div className="w-full h-full mt-20 px-5 sm:px-10 md:px-16 lg:px-14 xl:px-14 2xl:px-13">
            <div className="my-5">
              <span className="text-xl">
                <p className="m-1">Username</p>
              </span>
              <input
                type="text"
                value={Username}
                onChange={e => setUsername(e.target.value)}
                placeholder="Username"
                className="input input-bordered border-base-1 input-md w-full"
              />
            </div>
            <div className="my-5">
              <span className="text-xl">
                <p className="m-1">Password</p>
              </span>
              <input
                type="password"
                value={Password}
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
                className="input input-bordered border-base-1 input-md w-full"
              />
            </div>
            <span className="text-sm flex">
              <p className="">คุณมีบัญชีหรือไม่ ? ถ้ายังไม่ดี </p>
              <p
              onClick={register}
                className="ml-2 text-base-3"
              >
                กดที่นี่
              </p>
            </span>
            <div className="my-8 flex justify-center">
              <button
                onClick={login}
                className="btn btn-md sm:btn-md md:btn-md lg:btn-lg bg-base-3 text-base-2 hover:bg-base-1"
              >
                เข้าใช้งาน
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
