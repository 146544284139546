import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import NavBarUser from "./Navbar/Navbar";
import Configurl from "../Config";
import axios from "axios";
import LoadingPage from "../Component/Loading";
import { useNavigate } from "react-router-dom";
export default function Homeowner() {
  const [players, setplayers] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchbanner();
    fetchverify();
    fetchplayersonline();
  }, []);
  const [banner, setbanner] = useState();
  const fetchbanner = async () => {
    try {
      const response = await axios.get(
        Configurl.Url + "banner",
      );
      if (response.data.success) {
        setbanner(response.data.text)
      } 
    } catch (error) {}
  };

  const fetchverify = async () => {
    try {
      const response = await axios.get(
        Configurl.Url + "verify",
        Configurl.headers()
      );
      if (response.data.status == 200) {
      } else {
        localStorage.removeItem("BX_Token");
        Navigate("/login");
      }
    } catch (error) {}
  };

  const fetchplayersonline = async () => {
    try {
      const response = await axios.post(
        `${Configurl.Url}useronline`,
        {},
        Configurl.headers()
      );
      if (response.data.length > 0) {
        const sortedPlayers = response.data.sort((a, b) => a.id - b.id);

        // Create rows dynamically
        const newRows = sortedPlayers.map((player) =>
          createData(player.id, player.name, player.identifiers[0])
        );

        setRows(newRows);
        setplayers(response.data.length);
        setIsLoading(false);
      } else {
        const newRow = [
          createData("ไม่พบข้อมูล", "ไม่พบข้อมูล", "ไม่พบข้อมูล"),
        ];
        setRows(newRow);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const columns = [
    { id: "Id", label: "Id", minWidth: 300 },
    { id: "Name", label: "Name", minWidth: 170 },
    { id: "Steam", label: "Steam", minWidth: 170 },
  ];

  function createData(Id, Name, Steam) {
    return { Id, Name, Steam };
  }

  //   if (isLoading) {
  //     return <LoadingPage/>;
  //   }

  //   const rows = [
  //     createData("1", "Name", "ABC123"),
  //     createData("2", "Name", "DEF456"),
  //     createData("3", "Name", "GHI789"),
  //     createData("4", "Name", "JKL012"),
  //     createData("5", "Name", "MNO345"),
  //     createData("6", "Name", "PQR678"),
  //     createData("7", "Name", "STU901"),
  //   ];

  const cluser = async (user) => {
    try {
      Navigate(`/owner/userdetail?id=${user.Id}`);
    } catch (error) {}
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <NavBarUser />
      <div class="flex h-screen flex-1 flex-col py-10 px-2 sm:px-28 md:px-5 lg:px-24 xl:px-48 2xl:px-64 kanit-regular">
        <div class="h-10 kanit-regular drop-shadow-lg">
          <div class="w-full h-full bg-base-2 rounded-3xl flex justify-between items-center">
            <div class="w-2/12 h-full bg-base-1 rounded-l-3xl flex justify-center items-center">
              <p class="text-center text-base-2 text-xl">ประกาศ</p>
            </div>
            <div class="w-10/12 h-full overflow-hidden flex justify-center items-center">
              <p class="animate-scrolling-left1 text-center text-base-1 p-2 text-sm md:text-xl">
                { banner ? <>{banner}</> : <>BX DEV | ประกาศ อัพเดตสคริปต์ WEBADMIN สามารถอัพได้แล้ววันนี้ !!!</>}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10 w-full bg-base-2  border-8 border-base-3 rounded-xl overflow-x-auto p-10">
          <p className="text-xl">ผู้เล่นทั้งหมด {players}</p>
          {isLoading && <LoadingPage />}
          <TableContainer className="max-h-96">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="min-w-[300px] bg-gray-200 "
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(
                    page * rowsPerPage,
                    Math.min(page * rowsPerPage + rowsPerPage, rows.length)
                  ) // ปรับการ slice ให้ไม่เกินจำนวนข้อมูลจริง
                  .map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.username}
                      onClick={() => cluser(row)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} className="p-4 ">
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 7, 10]} // ตัวเลือกจำนวนแถวต่อหน้า
            component="div"
            count={rows.length} // จำนวนข้อมูลทั้งหมด
            rowsPerPage={rowsPerPage} // กำหนดจำนวนแถวต่อหน้า
            page={page} // หน้าปัจจุบัน
            onPageChange={handleChangePage} // เปลี่ยนหน้า
            onRowsPerPageChange={handleChangeRowsPerPage} // เปลี่ยนจำนวนแถวต่อหน้า
          />
        </div>
      </div>
    </>
  );
}
