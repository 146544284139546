import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import NavBarUser from "../Navbar/Navbar";
import Configurl from "../../Config";
import axios from "axios";
import LoadingPage from "../../Component/Loading";
import { useNavigate } from "react-router-dom";
export default function ADmin() {
  const [players, setplayers] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [listadmin, Setlistadmin] = useState(null);
  const [admin, Setadmin] = useState(null);
  const [adminid, Setadminid] = useState(null);
  const [adminusername, Setadminusername] = useState(null);
  const [adminservername, Setadminservername] = useState(null);
  const [adminstatus, Setadminstatus] = useState(null);
  const [adminipserver, Setadminipserver] = useState(null);
  const [adminportserver, Setadminportserver] = useState(null);
  useEffect(() => {
    fetchbanner()
    fetchverify();
    fetchplayersonline();
  }, []);
  const [banner, setbanner] = useState();
  const fetchbanner = async () => {
    try {
      const response = await axios.get(
        Configurl.Url + "banner",
      );
      if (response.data.success) {
        setbanner(response.data.text)
      } 
    } catch (error) {}
  };

  const fetchverify = async () => {
    try {
      const response = await axios.get(
        Configurl.Url + "verify",
        Configurl.headers()
      );
      if (response.data.status == 200) {
      } else {
        localStorage.removeItem("BX_Token");
        Navigate("/login");
      }
    } catch (error) {}
  };

  const fetchplayersonline = async () => {
    try {
      const response = await axios.post(
        `${Configurl.Url}listadmin`,
        {},
        Configurl.headers()
      );
      if (response.data.success) {
        const sortedPlayers = response.data.user.sort((a, b) => a.id - b.id);

        // Create rows dynamically
        const newRows = sortedPlayers.map((player) =>
          createData(
            player.id,
            player.username,
            player.status == 0
              ? "ปิดการใช้งาน"
              : player.status == 1
              ? "เปิดการใช้งาน"
              : player.status,
            player.servername,
            player.ipserver,
            player.portserver,
            player.status,
          )
        );

        setRows(newRows);
        setplayers(response.data.length);
        setIsLoading(false);
      } else {
        const newRow = [
          createData("ไม่พบข้อมูล", "ไม่พบข้อมูล", "ไม่พบข้อมูล"),
        ];
        setRows(newRow);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const columns = [
    { id: "Id", label: "Id", minWidth: 300 },
    { id: "username", label: "Name", minWidth: 170 },
    { id: "status", label: "status", minWidth: 170 },
  ];

  function createData(Id, username, status, servername, ipserver, portserver, status01) {
    return { Id, username, status, servername, ipserver, portserver, status01 };
  }

  //   if (isLoading) {
  //     return <LoadingPage/>;
  //   }

  //   const rows = [
  //     createData("1", "Name", "ABC123"),
  //     createData("2", "Name", "DEF456"),
  //     createData("3", "Name", "GHI789"),
  //     createData("4", "Name", "JKL012"),
  //     createData("5", "Name", "MNO345"),
  //     createData("6", "Name", "PQR678"),
  //     createData("7", "Name", "STU901"),
  //   ];

  const chang = async () => {
    try {
      document.getElementById("my_modal_2").close();
      const data = {
        username: adminusername.toString(),
        servername: adminservername.toString(),
        serverip: adminipserver.toString(),
        serverport: adminportserver.toString(),
        status: adminstatus,
        id: adminid,
      };

      const res = await axios.post(
        `${Configurl.Url}editadmin`,
        data,
        Configurl.headers()
      );
      if (res.data.success) {
        fetchplayersonline();
      }
    } catch (error) {}
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <NavBarUser />
      <div class="flex h-screen flex-1 flex-col py-10 px-2 sm:px-28 md:px-5 lg:px-24 xl:px-48 2xl:px-64 kanit-regular">
        <div class="h-10 kanit-regular drop-shadow-lg">
          <div class="w-full h-full bg-base-2 rounded-3xl flex justify-between items-center">
            <div class="w-2/12 h-full bg-base-1 rounded-l-3xl flex justify-center items-center">
              <p class="text-center text-base-2 text-xl">ประกาศ</p>
            </div>
            <div class="w-10/12 h-full overflow-hidden flex justify-center items-center">
              <p class="animate-scrolling-left1 text-center text-base-1 p-2 text-sm md:text-xl">
                { banner ? <>{banner}</> : <>BX DEV | ประกาศ อัพเดตสคริปต์ WEBADMIN สามารถอัพได้แล้ววันนี้ !!!</>}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10 w-full bg-base-2  border-8 border-base-3 rounded-xl overflow-x-auto p-10">
          <p className="text-xl">แอดมินทั้งหมด {players}</p>
          {isLoading && <LoadingPage />}
          <TableContainer className="max-h-96">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="min-w-[300px] bg-gray-200 "
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(
                    page * rowsPerPage,
                    Math.min(page * rowsPerPage + rowsPerPage, rows.length)
                  ) // ปรับการ slice ให้ไม่เกินจำนวนข้อมูลจริง
                  .map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.username}
                      onClick={() => {
                        document.getElementById("my_modal_2").showModal();
                        Setadmin(true);
                        Setadminid(row.Id);
                        Setadminusername(row.username);
                        Setadminservername(row.servername);
                        Setadminstatus(row.status01);
                        Setadminipserver(row.ipserver);
                        Setadminportserver(row.portserver);
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} className="p-4 ">
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 7, 10]} // ตัวเลือกจำนวนแถวต่อหน้า
            component="div"
            count={rows.length} // จำนวนข้อมูลทั้งหมด
            rowsPerPage={rowsPerPage} // กำหนดจำนวนแถวต่อหน้า
            page={page} // หน้าปัจจุบัน
            onPageChange={handleChangePage} // เปลี่ยนหน้า
            onRowsPerPageChange={handleChangeRowsPerPage} // เปลี่ยนจำนวนแถวต่อหน้า
          />
        </div>
      </div>

      <dialog id="my_modal_2" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">แก้ไข USERADMIN </h3>
          {admin ? (
            <>
              <div className="mt-5">
                <div className="flex text-center justify-between">
                  <h3 className="font-bold text-lg">USER </h3>
                  <div className="w-10"></div>
                  <input
                    type="text"
                    value={adminusername}
                    onChange={(e) => Setadminusername(e.target.value)}
                    placeholder="USER"
                    className="input input-bordered w-full max-w-xs"
                  />
                </div>
                <div className="h-10"></div>
                <div className="flex text-center justify-between">
                  <h3 className="font-bold text-lg">SERVERNAME </h3>
                  <div className="w-10"></div>
                  <input
                    type="text"
                    value={adminservername}
                    onChange={(e) => Setadminservername(e.target.value)}
                    placeholder="USER"
                    className="input input-bordered w-full max-w-xs"
                  />
                </div>
                <div className="h-10"></div>
                <div className="flex text-center justify-between">
                  <h3 className="font-bold text-lg">IPSERVER </h3>
                  <div className="w-10"></div>
                  <input
                    type="text"
                    value={adminipserver}
                    onChange={(e) => Setadminipserver(e.target.value)}
                    placeholder="USER"
                    className="input input-bordered w-full max-w-xs"
                  />
                </div>
                <div className="h-10"></div>
                <div className="flex text-center justify-between">
                  <h3 className="font-bold text-lg">PORTSERVER </h3>
                  <div className="w-10"></div>
                  <input
                    type="text"
                    value={adminportserver}
                    onChange={(e) => Setadminportserver(e.target.value)}
                    placeholder="USER"
                    className="input input-bordered w-full max-w-xs"
                  />
                </div>
                <div className="h-10"></div>
                <div className="flex text-center justify-between">
                  <h3 className="font-bold text-lg">STATUS </h3>
                  <div className="flex row px-20">
                    <h3 className="font-bold text-lg mx-5">ปิด </h3>
                    <input
                      checked={adminstatus == 1 ? true : false}
                      type="checkbox"
                      onChange={(e) => Setadminstatus(e.target.checked ? 1 : 0)}
                      className="toggle toggle-accent mx-5"
                      defaultChecked
                    />
                    <h3 className="font-bold text-lg mx-5">เปิด </h3>
                  </div>
                </div>
                <div className="h-10"></div>

                <div
                  onClick={chang}
                  className="h-10 w-full bg-base-2 rounded-lg text-black text-xl text-center py-1"
                >
                  {" "}
                  ยืนยันการแก้ไข
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
