import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Configurl from "../Config";
import Swal from "sweetalert2";

export default function RegisterServer() {
    const Navigate = useNavigate();
    const [Username, setUsername] = useState("");
    const [Password, setPassword] = useState("");
    const [Passwordconfirm, setPasswordconfirm] = useState("");
    const [ipserver, setipserver] = useState("");
    const [portserver, setportserver] = useState("");
    const [servername, setservername] = useState("");
    // const register = async () => {
    //     try {
    //         Navigate('/register');
    //     } catch (error) {
    //       Swal.fire({
    //         title: "เกิดข้อผิดพลาด",
    //         text: error.message,
    //         icon: "error",
    //         timer: 1000
    //   });
    //     }
    // }
  
    const register = async () => {
      try {
        if(Password === Passwordconfirm) {
          const data = {
            username: Username,
            password: Password,
            servername: servername,
            serverip: ipserver,
            serverport: portserver,
          };
          
          const res = await axios.post(Configurl.Url + "registerserver", data);
          if (res.data.status === 200) {
            // payload()
            await Swal.fire({
              title: res.data.message,
              text: res.data.message,
              icon: "success",
              timer: 1500,
            });
            Navigate('/login')
          }
          if (res.data.status === 400) {
            Swal.fire({
              title: res.data.error,
              text: res.data.error,
              icon: "error",
              timer: 1500,
            });
          }
        }else {
          Swal.fire({
            title: "รหัสผ่านไม่ตรงกัน",
            text: "รหัสผ่านไม่ตรงกัน",
            icon: "error",
            timer: 1500,
          });
        }
      } catch (error) {
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: error.message,
          icon: "error",
          timer: 1500,
        });
      }
    };
  return (
    <>
      <div className="flex h-screen flex-1 flex-col px-16 py-24 sm:px-24 md:px-36 lg:px-72 xl:px-72 2xl:px-72 sm:py-28 md:py-32 lg:py-32 xl:py-36 2xl:py-36 kanit-regular">
        <div className=" w-full bg-base-2 xl:w-8/12 2xl:w-8/12 xl:mx-44 2xl:mx-52 border-8 border-base-3 rounded-xl">
          <span className="text-center text-2xl">
            <p className="m-5">Server</p>
          </span>
          <div className="w-full h-full mt-1 px-5 sm:px-10 md:px-16 lg:px-14 xl:px-14 2xl:px-13 sm:mt-10 md:mt-16 lg:mt-14 xl:mt-14 2xl:mtpx-13">
            <div className="my-5 w-full lg:flex xl:flex 2xl:flex">
              <div className="w-full">
                <span className="text-xl">
                  <p className="m-1">ServerName</p>
                </span>
                <input
                  type="text"
                  value={servername}
                  onChange={e => setservername(e.target.value)}
                  placeholder="ServerName"
                  className="input input-bordered border-base-1 input-md w-full"
                />
              </div>
              <div className="lg:w-20 xl:w-20 2xl:w-20"></div>
              <div className="w-full">
                <span className="text-xl">
                  <p className="m-1">IPServer</p>
                </span>
                <input
                  type="text"
                  value={ipserver}
                  onChange={e => setipserver(e.target.value)}
                  placeholder="IPServer"
                  className="input input-bordered border-base-1 input-md w-full"
                />
              </div>
              <div className="lg:w-20 xl:w-20 2xl:w-20"></div>
              <div className="w-full">
                <span className="text-xl">
                  <p className="m-1">PortServer</p>
                </span>
                <input
                  type="text"
                  value={portserver}
                  onChange={e => setportserver(e.target.value)}
                  placeholder="PortServer"
                  className="input input-bordered border-base-1 input-md w-full"
                />
              </div>
            </div>
            <div className="my-5 w-full lg:flex xl:flex 2xl:flex">
            <div className="w-full">
                <span className="text-xl">
                  <p className="m-1">Username</p>
                </span>
                <input
                  type="text"
                  value={Username}
                  onChange={e => setUsername(e.target.value)}
                  placeholder="Password"
                  className="input input-bordered border-base-1 input-md w-full"
                />
              </div>
              <div className="lg:w-20 xl:w-20 2xl:w-20"></div>
              <div className="w-full">
                <span className="text-xl">
                  <p className="m-1">Password</p>
                </span>
                <input
                  type="password"
                  value={Password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Password"
                  className="input input-bordered border-base-1 input-md w-full"
                />
              </div>
              <div className="lg:w-20 xl:w-20 2xl:w-20"></div>
              <div className="w-full">
                <span className="text-xl">
                  <p className="m-1">Passwordconfirm</p>
                </span>
                <input
                  type="password"
                  value={Passwordconfirm}
                  onChange={e => setPasswordconfirm(e.target.value)}
                  placeholder="Passwordconfirm"
                  className="input input-bordered border-base-1 input-md w-full"
                />
              </div>
            </div>
            <div className="my-10 flex justify-center">
              <button
              onClick={register}
                className="btn btn-md sm:btn-md md:btn-md lg:btn-lg bg-base-3 text-base-2 hover:bg-base-1"
              >
                สมัครสมาชิก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
